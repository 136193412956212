// $scheme: (#505160, #68829E, #aedb38); // Crisp & Dramatic
// $scheme: (#375e97, #fb6542, #ffbb00); // Primary Colors With a Vibrant Twist
// $scheme: (#98DBC6, #5BC8AC, #E6D72A); // Refreshing & Pretty
// $scheme: (#1995ad, #a1d6e2, #bcbabe); // Icy Blues and Grays
// $scheme: (#5d535e, #ec96a4, #dfe166); // Birds & Berries
// $scheme: (#011a27, #063852, #e6df44); // Day & Night
// $scheme: (#011a27, #68829E, #51cdf1); // Day & Night
// $scheme: (#011a27, #557f97, #b7b7b7); // Day & Night
// $scheme: (#505160, #376aa1, #4CD4B0); // Crisp & Dramatic
//$scheme: (#505160, #2980B9, #44BBFF); // Crisp & Dramatic
//$scheme: (#050202, #6aef86, #3dd45e); // Crisp & Dramatic



// applied scheme
$black: rgba(0, 0, 0, 0.8);
$primary-color: #6aef86;
$primary-color-dark:#40bb5a;
$primary-color-darker:#1a722f;
$secondary-color: #2bd609;

// basics
$white: #fff;
$grey: #423d3d;
$blue: #0000ff;


// derived colors
$light-grey: lighten($grey, 55%);

$link-color: lighten($primary-color, 30%);

$primary-text-color: $grey;

$code-background: lighten(#000, 25%);

$secondary-color-on-dark: saturate(lighten($secondary-color, 30%), 30%);

$color1: $primary-color-dark;
$color2: $secondary-color;