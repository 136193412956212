footer {
    @include no-smooth-font();
    width: 100%;
    background: #20242e;
    //margin-top: 40px;
    padding-top: 20px;

    .hidden-xs {
        @include media-breakpoint-down(xs) {
            display: none !important;
        }
    }
    
    .top-footer {
        padding-bottom: 20px;
    }
    .fb-page {
        border: 10px solid rgba(0, 0, 0, 0.2);
    }
    .link-footer {
        width: 100%;
        float: left;
        margin-top: -10px;
    }
    .link-footer li {
        width: 100%;
        height: 50px;
        line-height: 50px;
        //border-bottom: 1px dashed rgba(255,255,255,0.07);
        list-style: none;
    }
    a {
        color: $primary_color;
    }
    a:hover {
        color: #cad43d;
    }
    .title-footer {
        width: 100%;
        float: left;
        margin-bottom: 30px;
    }
    .title-footer span {
        font-size: 16px !important;
        position: relative;
        color: #00b7ff;
        text-transform: uppercase;
    }
    .title-footer span::after {
        content: "";
        width: 100%;
        height: 1px;
        background: #00b7ff;
        position: absolute;
        top: 30px;
        left: 0px;
    }
    .info-items {
        width: 100%;
        float: left;
        margin-bottom: 15px;
        color: #fff;
    }
    .info-items .fa {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 1px dashed rgba(255, 255, 255, 0.4);
        line-height: 30px;
        text-align: center;
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.5);
    }
    .block-content .list-post-items {
        width: 100%;
        float: left;
    }
    .list-post-items {
        width: 100%;
        margin-bottom: 15px;
    }
    .list-post-items .list-post-items-time {
        width: 80px;
        background-color: #ff7149;
        color: #fff !important;
        text-align: center;
        float: left;
        height: 80px;
        padding: 0px;
    }
    .time-top,
    .time-bottom {
        height: 40px;
        line-height: 40px;
        float: left;
        width: 100%;
        font-weight: bold;
        font-size: 16px;
    }
    .time-bottom {
        background-color: #f95a2d;
        font-size: 13px;
    }
    .list-post-items .list-post-items-content {
        background-color: #fff;
        height: 80px;
        padding: 10px 10px 10px 90px !important;
    }
    .list-post-items .entry-meta {
        font-size: 16px !important;
    }
    .entry-meta {
        width: 80px;
        height: 80px;
    }
    .list-items-time .entry-meta {
        width: 60px !important;
        height: 60px !important;
    }
}