// @import 'variables/*';
// //bootstrap
// @import '../vendor/bootstrap/scss/bootstrap.scss';
.carousel {
    background-color: #ddd;
    margin-top: 50px;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0; // override <ol> default
    // Use the .carousel-control's width as margin so we don't overlay those
    margin-right: $carousel-control-width;
    margin-left: $carousel-control-width;
    margin-top: 0;
    margin-bottom: 0.2rem;
    list-style: none;
    li {
        border: solid 1px #000000;
        position: relative;
        flex: 0 1 auto;
        width: 1rem;
        height: 1rem;
        margin-right: 0.25rem;
        margin-left: 0.25rem;
        text-indent: -999px;
        background-color: $white;
        // Use pseudo classes to increase the hit area by 10px on top and bottom.
        //   &::before {
        //     position: absolute;
        //     top: -10px;
        //     left: 0;
        //     display: inline-block;
        //     width: 100%;
        //     height: 10px;
        //     content: "";
        //   }
        //   &::after {
        //     position: absolute;
        //     bottom: -10px;
        //     left: 0;
        //     display: inline-block;
        //     width: 100%;
        //     height: 10px;
        //     content: "";
        //   }
    }
    .active {
        background-color: $primary-color-dark;
    }
}

.first_slide_bgnd {
    height: 75vh;
    background-size: contain;
    background-position: center 5%;
    background-repeat: no-repeat;
    @include media-breakpoint-down(sm) {
        background-position: center 25%;
        height: 60vh
    }
    @include media-breakpoint-down(xs) {
        background-position: center 45%;
        height: 40vh
    }
}

.slide-info-block {
    text-align: center;
    position: absolute;
    //top: 70%;
    bottom: 2.5rem;
    //left: 50%;
    width: 100%;
    color: $white;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    //transform: translate(-50%, -50%);
    .headertext {
        margin: 10px;
        font-size: 2.5rem;
        text-shadow: -1px 0 4px #000000, 1px -1px 4px #000000, -1px 1px 4px #000000, 1px 1px 4px #000000, -2px 0 4px #000000, 2px 0 4px #000000, 0 2px 4px #000000, 0 -2px 4px #000000;
        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
        @include media-breakpoint-down(xs) {
            font-size: 1.5rem;
        }
    }
    .tagline {
        margin: 10px;
        font-size: 1.5rem;
        text-shadow: -1px 0 4px #000000, 1px -1px 4px #000000, -1px 1px 4px #000000, 1px 1px 4px #000000, -2px 0 4px #000000, 2px 0 4px #000000, 0 2px 4px #000000, 0 -2px 4px #000000;
        @include media-breakpoint-down(sm) {
            font-size: 1.1rem;
        }
        @include media-breakpoint-down(xs) {
            font-size: 0.8rem;
        }
    }
}

/////////// other slides ///////////////////
.slide-heading {
    border-bottom: solid 1px;
    padding-bottom: 0.5em;
    width: fit-content;
}

.features-slide {
    width: 100%;
    // margin-top:2rem;
    // margin-bottom: 2rem;
    padding: 20px;
    .feature-row {
        .feature {
            // border-style: dashed;
            // border-width: 1px;
            // border-color: #fff;
            padding: 10px;
            text-align: center;
            i {
                font-size: 3rem !important;
            }
        }
    }
}

.download-slide {
    padding: 20px;
    .download-row {
        .download-item {
            // border-style: dashed;
            // border-width: 1px;
            // border-color: #fff;
            padding: 10px;
            //width: 120px;
            text-align: center;
            i {
                font-size: 3rem !important;
            }
        }
    }
    a {
        //background-color: $white;
        color: $primary-color-darker;
        text-decoration: none;
        .download-button {
            border: solid 1px $primary-color-darker;
            border-radius: 10px;
            width: fit-content;
            padding: 10px;
            margin: 10px auto;
        }
        .download-button:hover {
            background-color: $primary-color-darker;
            color: $white;
        }
    }
    &.color2 a {
        //background-color: $primary-color-darker;
        color: $white;
        text-decoration: none;
        .download-button {
            border: solid 1px $white;
            border-radius: 10px;
            width: fit-content;
            padding: 10px;
            margin: 10px auto;
        }
        .download-button:hover {
            background-color: $white;
            color: $primary-color-darker;
        }
    }
}

.info-slide {
    padding: 20px;
}

.video-demo-slide{
    padding: 20px;
}

.color1 {
    background-color: $white;
    color: $primary-color-darker;
}

.color2 {
    background-color: $primary-color-darker;
    color: $white;
}