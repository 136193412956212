@import "/root/website-letmkara/assets/css/variables/colors.scss";
@import "/root/website-letmkara/assets/css/variables/font-sizes.scss";
@import "/root/website-letmkara/assets/css/variables/layout.scss";
@import "/root/website-letmkara/assets/css/variables/transitions.scss";

//bootstrap
@import '../vendor/bootstrap/scss/bootstrap.scss';
$fa-font-path: '../vendor/font-awesome/fonts';
@import '../vendor/font-awesome/scss/font-awesome';
// Mixins
@import "/root/website-letmkara/assets/css/mixins/clearfix.scss";
@import "/root/website-letmkara/assets/css/mixins/font-size.scss";
@import "/root/website-letmkara/assets/css/mixins/full.scss";
@import "/root/website-letmkara/assets/css/mixins/hoverlink.scss";
@import "/root/website-letmkara/assets/css/mixins/no-smooth-font.scss";
@import "/root/website-letmkara/assets/css/mixins/text-shadow.scss";

// Mixins
@import "/root/website-letmkara/assets/css/components/layout/footer.scss";
@import "/root/website-letmkara/assets/css/components/layout/header.scss";
@import "/root/website-letmkara/assets/css/components/layout/menu/footer-menu.scss";
@import "/root/website-letmkara/assets/css/components/layout/menu/screen-menu.scss";
@import "/root/website-letmkara/assets/css/components/layout/richtext-container.scss";

// html {
//     box-sizing: border-box;
// }
@import 'index.scss';

*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

body {
    background-color: $white;
    color: #000000;
    //font-family: 'Lato', sans-serif;
    //font-weight: 200;
    padding: 0;
    margin: 0;
    //line-height: 1;
}

.bottom_block {
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    color: $white;
    transform: translateX(-50%);
}

.screen {
    height: 100vh;
    position: relative;
    &.half {
        height: 50vh;
    }
    &.color1 {
        background-color: $color1;
    }
    &.color2 {
        background-color: $color2;
    }
}

// animations
.vertical_pulsate {
    animation: vertical_pulsate 3s infinite;
}

@keyframes vertical_pulsate {
    0% {
        transform: translate(-50%, 0px);
    }
    80% {
        transform: translate(-50%, 0px);
    }
    85% {
        transform: translate(-50%, 10px);
    }
    90% {
        transform: translate(-50%, 0px);
    }
    95% {
        transform: translate(-50%, 10px);
    }
    100% {
        transform: translate(-50%, 0px);
    }
}