// container for documents (blog, about, download, faq, ...)
.richtext-container {
    padding-top: 60px;
    padding-bottom: 20px;
    width: 100%;
    max-width: 50vw;
    margin: 0 auto;
    word-wrap: break-word;
    @include media-breakpoint-up(sm) {
        max-width: 70vw;
    }
    @include media-breakpoint-up(xs) {
        max-width: 90vw;
    }
    // typography, paragraph,...
    p {
        font-size: 1em;
        margin-bottom: 10px;
        margin-top: 0px;
    }
    a {
        color: #28a745;
    }
    // lists
    ol, ul {
        text-align: left;
        margin-left: 2em;
    }
    // heading
    h1 {
        font-size: 2.5em;
        font-weight: 500;
    }
    h2 {
        font-size: 2.2em;
        font-weight: 500;
    }
    h3 {
        font-size: 1.75em;
        font-weight: 400;
    }
    h4 {
        font-size: 1.6em;
        font-weight: 400;
    }
    h5 {
        font-size: 1.4em;
        font-weight: 400;
    }
    h6 {
        font-size: 1.2em;
        font-weight: 400;
    }
    h7 {
        font-size: 0.9em;
        font-weight: 400;
    }
    h8 {
        font-size: 0.7em;
        font-weight: 400;
    }
    table {
		width: 100%;
		max-width: 100%;
		margin-bottom: 1rem;
		background-color: transparent;
        thead {
			border: solid 1px;
			tr {
				background-color: #ddd;
                border: solid 1px;
                th {
					padding: 0.75rem;
					vertical-align: middle;
					text-align: center;
                    border: solid 1px;
                }
            }
        }
        tbody {
            border: solid 1px;
            tr {
                border: solid 1px;
                td {
					padding: 0.75rem;
					vertical-align: top;
                    border: solid 1px;
                }
            }
        }
    }
}