header {
	width: 100%;
	padding: 10px 0px 10px 0px;
	position: fixed;
	top: 0;
	background-color: rgba(0, 0, 0, 0.9);
	color: $white;
	z-index: 20;
	//height: $header-height;

	.logo {
		float: left;
		margin-left: 10px;
		height: 32px;
	}

	@include media-breakpoint-down(sm) {
		padding: 8px 0px 8px 0px;
	}
}

.under-header{
	height: $header-height
}