.screen-menu {
    float: right;    
    margin-right: 10px;
    ul {
        margin:0;
        li {
            padding: 0 5px;
            display: inline-block;
        }
    }
    // a {
    //     @include hoverlink();
    // }
    .collapsed-menu {
        display: none;
        margin-right: 0px;
        margin-bottom: 0px;
        //margin-top: -2px;
        //margin-right: -12px;
        cursor: pointer;
        i {
            font-size: 30px !important;
        }
        &~input {
            display: none;
            &:checked~ul {
                display: block;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        ul {
            display: none;
            position: absolute;
            left: 0;
            background-color: $black;
            width: 100%;
            //padding: 30px;
            li {
                display: block;
                padding: 5px;
                @include font-size(md);
            }
        }
        .collapsed-menu {
            display: block;
        }
    }
    // mainmenu
    li>a.wsite-menu-item {
        display: block;
        padding: 8px 12px;
        border: 2px solid transparent;
        border-radius: 2px;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0.05em;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
    }
    li#active>a.wsite-menu-item,
    li>a.wsite-menu-item:hover {
        border: 2px solid #ffffff;
    }
}