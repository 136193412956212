// * ———————————————————————————————————————————————————————— * //
// * 	Font sizes
// * ———————————————————————————————————————————————————————— * //

$font-sizes: (
	xxl: 80px,
	xl: 70px,
	lg: 46px,
	md: 30px,
	sm: 16px,
	xs: 13px,
	icon: 20px,
	icon2x: 40px,
) !default;